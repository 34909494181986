<template>
  <UpsellMenuItem
    title="Upsell Menu Items"
    addText="Add Upsell Item"
    addModalTitle="Select the item, category, or store you would like to assign upsell items to:"
    addExlcusionaryText="Add Items to Exclude from Upsell"
    addExclusionaryModalTitle="Select the item, category, or store level you would like to exclude upsell items from:"
    hintText="Upsell items with modifiers or sizes are not supported and should not be set, users will not be able to select sizes or modifications"
    :upsellAddonItems="currentItems"
    @handleTargetSelection="handleTargetSelection"
    @onEditItem="onEditItem"
    @onDeleteItem="onDeleteItem"
  />
</template>
 
<script>
import UpsellMenuItem from "components/upsell/upselladdon_menu_item.vue";
import { UpsellDataHelper } from "mixins/upsell.js";
import { mapActions } from "vuex";

export default {
    components: {
        UpsellMenuItem,
    },
    mixins: [UpsellDataHelper],

    methods: {
        handleTargetSelection(target) {
            this.$router.push({
                name: "upsell-menu-item-create",
                query: {
                    targetID: target.id,
                    targetName: target.title,
                    type: target.type,
                    exclusionary: target.exclusionary
                },
            });
        },
        onEditItem(item) {
            this.$router.push({
                name: "upsell-menu-item-edit",
                params: {
                    TargetID: `${item.TargetID}${item.exclusionary ? 'e' : ''}`,
                },
            });
        },
        onDeleteItem(item) {
            let [targetID, exclusionary] = item.split('-')
            let waypointID = this.$route.params.store;
            return this.deleteUpsellItem({
                waypointID,
                targetID,
                exclusionary: exclusionary === 'true'
            }).then(() => {});
        },
        ...mapActions("UpsellStore", ["deleteUpsellItem"]),
    },
};
</script>

<style lang="scss" scoped>
</style>
