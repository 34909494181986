var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("UpsellMenuItem", {
    attrs: {
      title: "Upsell Menu Items",
      addText: "Add Upsell Item",
      addModalTitle:
        "Select the item, category, or store you would like to assign upsell items to:",
      addExlcusionaryText: "Add Items to Exclude from Upsell",
      addExclusionaryModalTitle:
        "Select the item, category, or store level you would like to exclude upsell items from:",
      hintText:
        "Upsell items with modifiers or sizes are not supported and should not be set, users will not be able to select sizes or modifications",
      upsellAddonItems: _vm.currentItems,
    },
    on: {
      handleTargetSelection: _vm.handleTargetSelection,
      onEditItem: _vm.onEditItem,
      onDeleteItem: _vm.onDeleteItem,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }